.App {
  text-align: center;
  width: 100vw;
  height: 100%;
  font-family: Raleway;
  background-color: #FFF9F1;
  margin-top: 103px;
  top: 0;
  min-height: 100vh;

}

body { 
  overflow-x:hidden;

}
html { 
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: white;
}

h3 {
  font-size: 24px !important;
}

.homePageContainer {
  display: flex;
  /* height: 1000px; */
  height: 100vh;
  background-image: url('maybecover.jpg');
  /* background-image: url('beachphoto.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 50%; */
  /* background-size: 50% 100%; */
  
}
@media only screen and (max-width: 600px){
  .homePageContainer {
    flex-direction: column;
    width: 100%;
    background-size: 100%;
    height: 280px;
  
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px){
  .homePageContainer {
    flex-direction: column;
    width: 100%;
    background-size: 100%;
    height: 500px;
  
  }
}
@media only screen and (min-width: 450px) and (max-width: 600px){
  .homePageContainer {
    flex-direction: column;
    width: 100%;
    background-size: 100%;
    height: 375px;
  
  }
}

.photoDiv {
  width: 50%;
  overflow: hidden;
  /* position: relative;
  right: 60px; */
  /* background-image: url('nikkiandkai3screenshot.jpg ');
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 50%; */
  /* background-size: 50% 100%; */ 
  /* opacity:calc(80%); */
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.photoDiv img { 
  width: 100%;
}

@media only screen and (max-width: 620px){
  .photoDiv {
    width: 100%;
    background-position: contain;
  }
}

.textDiv {
  flex: 1;
  background-color:antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
}

.textDiv p { 
  width: 50%;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.registryPage {
  /* background-image: url('images/franschoek.jpeg '); */
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

.registryTextContainer {
  opacity:calc(80%);
  /* background-color: antiquewhite; */
  height: 80%;
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction:column;
}

.rsvpPhoto { 
  height: 100vh;
  background-image: url('rsvpphoto.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.photoOne {
  background-image: url('photoOne.JPG');
  background-repeat: no-repeat;
  background-size: cover;
}

.photoTwo {
  background-image: url('photoTwo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.lindaCheeseFries {
  background-image: url('lindacheesefries.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}  

.charlotte { 
  background-image: url('charlotte.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.kaiphd { 
  background-image: url('kaiphd.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}


.sfsunset { 
  background-image: url('sfsunset.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}